import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout, Form, Input, Button, Card, Typography, message, Divider, Space } from 'antd';
import { UserOutlined, LockOutlined, HomeOutlined } from '@ant-design/icons';
import directus from '../utils/directusClient';
import { useDispatch } from 'react-redux';
import { fetchProperties } from '../redux/propertiesSlice';
import { fetchLeasings } from '../redux/leasingsSlice';
import { AppDispatch } from '../redux/store';
import { fetchTenants } from '../redux/tenantsSlice';
import { fetchExpenses } from '../redux/expensesSlice';

const { Content } = Layout;
const { Title, Text } = Typography;

const directusUrl = process.env.REACT_APP_ENV === 'prod' 
  ? "https://app.blurben.com:8055" 
  : 'http://localhost:8055';

const GoogleIcon = () => (
  <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"/>
    <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"/>
    <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"/>
    <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"/>
  </svg>
);

const LoginPage = () => {
  const dispatch = useDispatch<AppDispatch>();  
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleLogin = async (values: any) => {
    setLoading(true);
    
    try {
        // console.log('Directus instance user:', directus);
        const response = await directus.login(
            values.username || '', 
            values.password || ''
        );
    

        // message.success('Logged in successfully');

        // Dispatch global data
        dispatch(fetchProperties());
        dispatch(fetchLeasings());
        dispatch(fetchTenants());
        dispatch(fetchExpenses());

        navigate('/dashboard');
        // }
    } catch (error) {
    message.error('Login failed');
    } finally {
    setLoading(false);
    }
  };

  const handleGoogleLogin = () => {
    const redirectUrl = `${window.location.origin}/auth/callback`;
    const googleAuthUrl = `${directusUrl}/auth/login/google?redirect=${encodeURIComponent(redirectUrl)}`;
    window.location.href = googleAuthUrl;
  };

  return (
    <Layout style={{ 
      minHeight: '100vh',
      width: '100vw',
      margin: 0,
      padding: 0,
      background: `url(${require('../assets/dashboard_sample.png')})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      overflow: 'hidden',
      position: 'fixed',
      top: 0,
      left: 0,
    }}>
      <div style={{
        minHeight: '100vh',
        width: '100%',
        backdropFilter: 'blur(4px) saturate(150%)',
        backgroundColor: 'rgba(240, 242, 245, 0.6)',
        padding: '50px 20px',
        position: 'absolute',
        top: 0,
        left: 0,
      }}>
        <div style={{ textAlign: 'center', marginBottom: 24, cursor: 'pointer' }} onClick={() => {
          if (process.env.REACT_APP_ENV === 'prod') {
            window.location.href = 'https://blurben.com';
          } else {
            navigate('/');
          }
        }}>
          <Space>
            <HomeOutlined style={{ fontSize: '24px', color: '#1C4E80' }} />
            <Title level={3} style={{ margin: 0, color: '#1C4E80' }}>Blurben</Title>
          </Space>
        </div>

        <Card
          style={{
            maxWidth: 480,
            margin: '0 auto',
            borderRadius: 8,
            boxShadow: '0 8px 24px rgba(0,0,0,0.15)'  // Enhanced shadow for better contrast
          }}
        >
          <div style={{ textAlign: 'center', marginBottom: 32 }}>
            <Title level={2} style={{ marginBottom: 8 }}>Welcome back!</Title>
            <Text type="secondary">
              Log in to manage your properties
            </Text>
          </div>

          <Button 
            icon={<GoogleIcon />}
            size="large"
            style={{ 
              width: '100%', 
              marginBottom: 24,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '8px'  // Adds space between icon and text
            }}
            onClick={handleGoogleLogin}
          >
            Continue with Google
          </Button>

          <Divider>Or</Divider>

          <Form
            layout="vertical"
            onFinish={handleLogin}
            requiredMark={false}
          >
            <Form.Item
              name="username"
              rules={[{ required: true, message: 'Please enter your username!' }]}
            >
              <Input 
                prefix={<UserOutlined />} 
                placeholder="Username" 
                size="large"
              />
            </Form.Item>
            
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Please enter your password!' }]}
            >
              <Input.Password 
                prefix={<LockOutlined />} 
                placeholder="Password" 
                size="large"
              />
            </Form.Item>

            <Form.Item>
              <Button 
                type="primary" 
                htmlType="submit" 
                loading={loading}
                style={{ width: '100%' }}
                size="large"
              >
                Log in
              </Button>
            </Form.Item>
          </Form>

          <Divider />

          <div style={{ textAlign: 'center' }}>
            <Text type="secondary">Don't have an account?</Text>
            <Button 
              type="link" 
              onClick={() => navigate('/signup')}
              style={{ marginLeft: 8 }}
            >
              Sign up
            </Button>
          </div>
        </Card>
      </div>
    </Layout>
  );
};

export default LoginPage;
