import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, useNavigate, useLocation, Navigate } from 'react-router-dom';
import AuthenticatedLayout from './components/AuthenticatedLayout';
import UnauthenticatedLayout from './components/UnauthenticatedLayout';
import PageContent from './components/PageContent';
import LoginPage from './views/Login';
import SignupPage from './views/SignupPage';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import AuthCallback from './views/AuthCallback';
import directus from './utils/directusClient';
import { readMe } from '@directus/sdk';
import ReactGA from 'react-ga4';
import { Layout, Card, Button } from 'antd';
import { MobileOutlined } from '@ant-design/icons';
import SearchBox from './components/SearchBox';
const { Content } = Layout;

// Define the type for the JWT token's payload
interface DecodedToken {
  exp: number;
}

const isProd = process.env.REACT_APP_ENV === 'prod';
const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID || 'G-LK6SY9JQ49';

// Only initialize GA in production
if (isProd && TRACKING_ID) {
  ReactGA.initialize(TRACKING_ID);
}

// Update Analytics component to check for production environment
const Analytics = () => {
  const location = useLocation();

  useEffect(() => {
    if (isProd && TRACKING_ID) {
      ReactGA.send({ hitType: "pageview", page: location.pathname });
    }
  }, [location]);

  return null;
};

const App = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const isProd = process.env.REACT_APP_ENV === 'prod';
  const isAppDomain = window.location.hostname === 'app.blurben.com';

  useEffect(() => {
    const checkInitialAuth = async () => {
      try {
        const user = await directus.request<any>(readMe());
        setIsAuthenticated(!!user);
      } catch (error) {
        setIsAuthenticated(false);
      }
    };
    checkInitialAuth();
  }, []);

  // Redirect to landing page if not on app domain in production
  if (isProd && !isAppDomain) {
    window.location.href = 'https://blurben.com';
    return null;
  }

  // Show loading state while checking authentication
  if (isAuthenticated === null) {
    return null; // or a loading spinner
  }

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Analytics />
        <Routes>
          {!isAuthenticated ? (
            <>
              <Route path="/login" element={
                <UnauthenticatedLayout>
                  <LoginPage />
                </UnauthenticatedLayout>
              } />
              <Route path="/signup" element={
                <UnauthenticatedLayout>
                  <SignupPage />
                </UnauthenticatedLayout>
              } />
              <Route path="*" element={<Navigate to="/login" replace />} />
            </>
          ) : (
            <>
              <Route path="/" element={<Navigate to="/dashboard" replace />} />
              <Route path="/*" element={
                <ProtectedRoute collapsed={collapsed} handleCollapse={handleCollapse} />
              } />
            </>
          )}
          <Route path="/auth/callback" element={<AuthCallback />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
};

// Add this new custom hook
const useSessionCheck = (redirectTo: string, shouldBeAuthenticated: boolean) => {
  const navigate = useNavigate();
  
  useEffect(() => {
    const checkSession = async () => {
      try {
        const user = await directus.request<any>(readMe());
        if (shouldBeAuthenticated && !user) {
          navigate('/login');
        } else if (!shouldBeAuthenticated && user) {
          navigate('/dashboard');
        }
      } catch (error) {
        console.error('Session check failed:', error);
        if (shouldBeAuthenticated) {
          navigate('/login');
        }
      }
    };

    checkSession();
    
    // Only set up interval for protected routes
    if (shouldBeAuthenticated) {
      const intervalId = setInterval(checkSession, 5 * 60 * 1000);
      return () => clearInterval(intervalId);
    }
  }, [navigate, redirectTo, shouldBeAuthenticated]);
};

// Update ProtectedRoute to use the hook
const ProtectedRoute = ({ collapsed, handleCollapse }: any) => {
  const [isMobile, setIsMobile] = useState(false);
  const [showAIChat, setShowAIChat] = useState(false);
  useSessionCheck('/login', true);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  // Only show mobile message after authentication
  if (isMobile && window.location.pathname !== '/login' && window.location.pathname !== '/signup') {
    return (
      <Layout style={{ 
        minHeight: '100vh',
        background: '#f0f2f5',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0
      }}>
        <Content style={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          padding: '20px',
          minHeight: '100vh'
        }}>
          {showAIChat && <SearchBox initialOpen={true} onClose={() => setShowAIChat(false)} />}
          <Card 
            style={{ 
              textAlign: 'center',
              maxWidth: '90%',
              width: '100%',
              borderRadius: '10px',
              boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
            }}
          >
            <MobileOutlined 
              style={{ 
                fontSize: '64px', 
                color: '#1C4E80',
                marginBottom: '20px'
              }} 
            />
            <h2 style={{ color: '#1C4E80', marginBottom: '16px' }}>
              Mobile App Coming Soon!
            </h2>
            <p style={{ color: '#666', marginBottom: '24px' }}>
              Our mobile app is under development! 
              Please switch to desktop for the complete experience.
            </p>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '12px', alignItems: 'center' }}>
              <Button 
                type="primary" 
                onClick={() => setShowAIChat(true)}
                style={{
                  width: '200px',
                  height: '40px',
                  fontSize: '16px',
                  background: '#1C4E80'
                }}
              >
                Talk to AI Assistant
              </Button>
              <Button 
                href="mailto:app.blurben@gmail.com"
                style={{
                  width: '200px',
                  height: '40px',
                  fontSize: '16px'
                }}
              >
                Contact Support
              </Button>
            </div>
          </Card>
        </Content>
      </Layout>
    );
  }

  return (
    <AuthenticatedLayout collapsed={collapsed} handleCollapse={handleCollapse}>
      <PageContent />
    </AuthenticatedLayout>
  );
};

// Update PublicOnlyRoute to use the hook
const PublicOnlyRoute = ({ children }: { children: React.ReactNode }) => {
  useSessionCheck('/dashboard', false);
  
  return <>{children}</>;
};

// const isTokenExpired = (token: string): boolean => {
//   try {
//     const decodedToken = jwtDecode<DecodedToken>(token);
//     const currentTime = Date.now() / 1000;
    
//     if (decodedToken.exp === undefined) {
//       return true;
//     }
//     return decodedToken.exp < currentTime;
//   } catch (error) {
//     console.error("Error decoding token:", error);
//     return true;
//   }
// };

export default App;
