// AuthenticatedLayout.js
import React, { useEffect } from 'react';
import { Layout } from 'antd';
import SideBar from './SideBar';
import AppHeader from './AppHeader';
import SearchBox from '../components/SearchBox'; // Import the new SearchBox component
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../redux/store';
import { fetchProperties } from '../redux/propertiesSlice';
import { fetchLeasings } from '../redux/leasingsSlice';
import { fetchTenants } from '../redux/tenantsSlice';
import { fetchExpenses } from '../redux/expensesSlice';
import { fetchPropertyFiles } from '../redux/filesSlice'


const { Content, Footer } = Layout;

const AuthenticatedLayout = ({ children, collapsed, handleCollapse }:any) => {
  const sidebarWidth = collapsed ? '50px' : '200px';
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
      dispatch(fetchProperties());
      dispatch(fetchLeasings());
      dispatch(fetchTenants());
      dispatch(fetchExpenses());
      dispatch(fetchPropertyFiles());
  }, [dispatch]);

  const animationStyle = `
    @keyframes bounce-pointer {
      0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
      }
      40% {
        transform: translateY(-15px);
      }
      60% {
        transform: translateY(-7px);
      }
    }
  `;

  return (
    <>
      <style>{animationStyle}</style>
      <Layout style={{ minHeight: '100vh' }}>
        <SideBar collapsed={collapsed} handleCollapse={handleCollapse} />
        <Layout style={{ marginLeft: sidebarWidth, transition: 'margin-left 0.2s' }}>
          <AppHeader isSidebarCollapsed={collapsed} />
          <Content style={{ padding: '16px', marginTop: '50px' }}>
            {children}
          </Content>
          <Footer style={{
              backgroundColor: '#f5f5f5',
              position: 'fixed',
              padding: '0 20px',
              height: "90px",
              bottom: 0,
              zIndex: 1,
              width: `calc(100% - ${sidebarWidth})`,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center'
          }}>
              <div style={{ position: 'relative' }}>
                  <div style={{
                      position: 'absolute',
                      top: '-40px',
                      left: '0px',
                      right: '0px',
                      animation: 'bounce-pointer 1s ease-in-out 3',
                      color: '#1890ff',
                      fontSize: '20px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '8px'
                  }}>
                      Ask AI <span style={{ transform: 'rotate(180deg)' }}>👆</span>
                  </div>
                  <SearchBox />
              </div>
          </Footer>


        </Layout>
      </Layout>
    </>
  );
};

export default AuthenticatedLayout;
